import React from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';

import Layout from '../../layouts';

import loadComponents from '../../components/Loadable';

const Checkout = loadComponents('checkout');
const SubpageHeader = loadComponents('subpage-header');

const page = { name: 'Pay now and complete application', slug: 'checkout' };

export default function CheckoutPage(props) {
  const { location } = props;
  const prevPage = location.state && location.state.prevPage;

  const queries = queryString.parse(location.search);
  const orderId = queries.order;

  return (
    <Layout
      title={page.name}
      location={`/${page.slug}/`}
      prevPage={prevPage}
      padding="0 0 5.625rem"
      cart
    >
      <SubpageHeader
        title={orderId ? page.name : `Unable to process request`}
        // cart
      />
      <Checkout {...props} />
    </Layout>
  );
}

export const CheckoutData = graphql`
  query CheckoutQuery {
    wp {
      shippingOptions {
        name
        price
        minAmount
        methodId
      }
    }
  }
`;
